import React from 'react'
import resume from '../assets/resume.jpeg'

function Resume() {
    return (
        <div>
          <img src={resume} alt="Resume" style={{width: '100%', height: 'auto'}} />
        </div>
      );
    }

export default Resume